'use client';

import {
  Typography, Box, Grid, SxProps,
} from '@mui/material';
import {
  useMemo, useState, useEffect,
  useCallback,
} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Link from 'next/link';
import theme from '@/theme';
import { handleGTMEvent } from '@/lib/utils/functions';
import { Linking } from '@/lib/types/interfaces/Linking';

interface Props {
  title: string
  seoCategories: Linking[],
  sx?: SxProps,
  padding?: {}
}

const ServicesList = ({
  seoCategories, sx, padding, title,
}: Props) => {
  const [categories, setCategories] = useState<Linking[]>([]);
  const [isShowAll, setShowAll] = useState(false);

  useEffect(() => {
    setCategories(seoCategories.slice(0, 15));
  }, [seoCategories]);

  const handleGetAllCategories = useCallback(async () => {
    if (!isShowAll) {
      setCategories(seoCategories);
      setShowAll(true);
      return;
    }
    setCategories(seoCategories.slice(0, 15));
    setShowAll(false);
  }, [isShowAll, seoCategories]);

  const renderServicesList = useMemo(() => categories?.map((item: Linking, index: number) => (
    <Grid
      item
      lg={12 / 5}
      xs={12 / 2}
      // eslint-disable-next-line react/no-array-index-key
      key={index}
    >
      <Box>
        <Box onClick={() => handleGTMEvent(item.link, 'click_on_seo_link')}>
          <Link
            href={item.link}
            aria-label={`Go to hire ${item.name}`}
          >
            <Typography
              variant='body1'
              component='h3'
              color={theme.palette.success.main}
            >
              {item.name}
            </Typography>
          </Link>
        </Box>
      </Box>

    </Grid>
  )), [categories]);

  return (
    <Box
      sx={sx}
      padding={padding}
    >
      <Typography
        variant='h6'
        fontWeight={600}
        component='h2'
      >
        {title}
      </Typography>

      <Box sx={{ marginTop: 3 }}>
        <Grid
          container
          spacing={1}
        >
          {renderServicesList}
        </Grid>
      </Box>

      {seoCategories.length > 15 && (
        <Typography
          variant='body1'
          component='div'
          color={theme.palette.success.main}
          sx={{
            marginTop: 3,
            display: 'flex',
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
              justifyContent: 'center',
            },
          }}
          onClick={handleGetAllCategories}
        >
          {!isShowAll ? 'Show' : 'Hide'}
          {' '}
          all
          {' '}
          {!isShowAll ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Typography>
      )}
    </Box>
  );
};

export default ServicesList;
