'use client';

import React, { useState, useEffect } from 'react';
// @ts-ignore
import { Tabs, Tab } from 'react-tabs-scrollable'; // this library have problems with types
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'react-tabs-scrollable/dist/rts.css';

import Link from 'next/link';
import { Box } from '@mui/material';
import apiClient from '@/apiClient';
import styles from './CategoryItems.module.scss';
import { Category } from '@/lib/types/interfaces/Category';
import { handleGTMEvent } from '@/lib/utils/functions';

const CategoryItems = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [items, setItems] = useState<Category[] | null>(null);
  const onTabClick = (_e: Event, index: number) => {
    setActiveTab(index);
  };
  const getCategories = async () => {
    const limit = 10;
    const sortOption = 'company_num';

    try {
      const { data } = await apiClient.get<{ data: Category[] }>(`/categories/list?limit=${limit}&sort=${sortOption}`);
      setItems(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className={styles.wrapper}>
      {
        items?.length ? (
          <Tabs
            activeTab={activeTab}
            leftNavBtnClassName={styles.navButtonLeft}
            rightNavBtnClassName={styles.navButtonRight}
            tabsContainerClassName={styles.tabsListContainer}
            rightBtnIcon={<ArrowForwardIosIcon />}
            leftBtnIcon={<ArrowBackIosIcon />}
            onTabClick={onTabClick}
            hideNavBtnsOnMobile={false}
          >
            {items.map((item) => (
              <Tab
                key={`tab-item-${item.id}`}
                className={styles.tabItem}
              >
                <Box
                  onClick={() => handleGTMEvent(`/services/${item.slug}`, 'click_on_category_on_home_page')}
                >
                  <Link
                    href={`/services/${item.slug}`}
                    aria-label={`Go to ${item.slug}`}
                  >
                    {item.name}
                  </Link>
                </Box>
              </Tab>
            ))}
          </Tabs>
        ) : null
      }
    </div>
  );
};

export default CategoryItems;
