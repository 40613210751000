'use client';

import {
  Typography, Card, CardContent, Grid,
} from '@mui/material';

import { useMemo } from 'react';
import Img from '@/components/Img';
import styles from './ProvidersSteps.module.scss';
import theme from '@/theme';
import { colorVariables } from '@/constants';

const ProvidersSteps = () => {
  const cardNumberStyles = {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: theme.palette.success.main,
    color: 'white',
    width: 32,
    height: 32,
    margin: 'auto',
    marginTop: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  };

  const cardContentStyles = {
    backgroundColor: '#F5F9F5',
    height: '100%',
    position: 'relative',
  };

  const imageSize = {
    height: 80,
    width: 300,
  };
  const cardSize = {
    height: 392,
    width: 330,
    margin: 'auto',
  };

  const descriptionStyles = {
    marginTop: 4,
    fontWeight: 600,
  };

  const stepsData = [
    {
      title: '01',
      description: (
        <>
          <span className='primary-text'>Search</span>
          {' '}
          our database of thousands of CRE services
        </>
      ),
      imagePath: '/media/Group%2056.webp',
      imageStyles: {
        position: 'absolute',
        bottom: '55px',
        left: '0',
        right: 'unset',
      },
      imageSize,
    },
    {
      title: '02',
      description: (
        <>
          <span className='primary-text'>Compare</span>
          {' '}
          to find the best provider
        </>
      ),
      imagePath: '/media/Card_.webp',
      imageStyles: {
        position: 'absolute',
        bottom: '55px',
        right: '0',
        left: 'unset',
      },
      imageSize: {
        height: 87,
        width: 280,
      },
    },
    {
      title: '03',
      description: (
        <>
          <span className='primary-text'>Hire</span>
          {' '}
          your service provider
        </>
      ),
      imagePath: '/media/card_3.png',
      imageStyles: {
        position: 'absolute',
        bottom: '44px',
        right: '0',
        left: 'unset',
      },
      imageSize: {
        height: 90,
        width: 292,
      },
    },
  ];

  const renderCards = useMemo(() => stepsData.map((item) => (
    <Grid
      item
      xs={12}
      lg={4}
      key={item.title}
      display={{ xs: 'flex' }}
    >
      <Card
        sx={{ ...cardSize, border: `3px solid ${colorVariables.borderColor}`, borderRadius: '50px' }}
        variant='outlined'
      >
        <CardContent sx={cardContentStyles}>
          <Typography
            sx={cardNumberStyles}
            component='div'
            className={styles.textNumber}
            color='text.secondary'
          >
            {item.title}
          </Typography>
          <Typography
            variant='h5'
            sx={descriptionStyles}
          >
            {item.description}
          </Typography>
          <Img
            src={item.imagePath}
            style={item.imageStyles}
            alt='Search'
            height={item.title === '01' ? 75 : item.imageSize.height}
            width={item.imageSize.width}
          />
          {item.title === '02' ? (
            <Img
              src={item.imagePath}
              style={{
                ...item.imageStyles,
                bottom: '-40px',
              }}
              alt='Search'
              height={item.imageSize.height}
              width={item.imageSize.width}
            />
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  )), []);

  return (
    <Grid
      container
      spacing={{ xs: 4, lg: 12 }}
      sx={{
        padding: '64px 0 34px 0',
      }}
    >
      {renderCards}
    </Grid>
  );
};

export default ProvidersSteps;
