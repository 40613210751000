'use client';

import { Typography } from '@mui/material';

import styles from './ServiceProviders.module.scss';
import ProvidersSteps from '@/page-components/Home/ServiceProviders/ProvidersSteps';
import theme from '@/theme';

const ServiceProviders = () => (
  <div className={styles.wrapper}>
    <Typography
      variant='h3'
      component='h2'
    >
      Find All Your
      {' '}
      <span className='primary-text'>CRE</span>
      {' '}
      Service Providers
    </Typography>
    <Typography
      variant='h4'
      component='h2'
      sx={{
        marginTop: 2,
        [theme.breakpoints.up('lg')]: {
          marginTop: 0,
        },
      }}
    >
      All in One Place
    </Typography>
    <ProvidersSteps />
  </div>
);

export default ServiceProviders;
